<template>
    <master-panel>
        <template v-slot:body>
            <v-row>
                <v-col cols="12">
                    <div class="title">
                        Asistente clínico
                    </div>
                    <member-delete :member="assistant"/>
                    <div v-if="!assistant" class="text-center">
                        <v-btn color="ternary" x-large depressed class="normal-btn"
                               @click="goTo({name: 'doctor.invitations.create', params: {role: 2}})">
                            Invitar a asistente clínico
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="title">
                        Secretaria
                    </div>
                    <member-delete :member="secretary"/>
                    <div v-if="!secretary" class="text-center">
                        <v-btn color="ternary" x-large depressed class="normal-btn"
                               @click="goTo({name: 'doctor.invitations.create', params: {role: 3}})">
                            Invitar a secretaria
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </template>
        <template v-slot:buttons>
            <v-btn color="primary" x-large depressed class="normal-btn" @click="goBack">
                Volver
            </v-btn>
        </template>
    </master-panel>
</template>

<script>
import MasterPanel from "@/components/layouts/MasterPanel";
import MemberDelete from "@/components/doctor/MemberDelete";
import LoadingSpinner from "@/components/common/LoadingSpinner";

export default {
    name: "Team",
    components: {LoadingSpinner, MemberDelete, MasterPanel},
    computed: {
        assistant(vm = this) {
            return vm.currentUser?.assistant || null
        },
        secretary(vm = this) {
            return vm.currentUser?.secretary || null
        },
    },
}
</script>

<style scoped>

.title {
    font-size: 14px !important;
}

</style>