<template>
    <v-row v-if="member" align="center">
        <v-col cols="9" md="10" class="pr-0 pb-0">
            <div class="member-container">
                {{ member.name }}
            </div>
        </v-col>
        <v-col cols="3" md="2" class="pl-1  pb-0">
            <v-btn color="primary" small depressed class="normal-btn" @click="onDeleteMember" :loading="loading">
                Eliminar
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import httpService from "@/services/httpService";
import {ROLES} from "@/utils/Constants";

export default {
    name: "MemberDelete",
    props: {
        member: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        async onDeleteMember() {
            const vm = this
            vm.loading = true

            let response = await httpService.post(`doctor/members/${vm.member.id}/delete`)
            // console.log('response', response)
            if(vm.member.role_id === ROLES.ASSISTANT) vm.currentUser.assistant = null
            if(vm.member.role_id === ROLES.SECRETARY) vm.currentUser.secretary = null
            vm.toast(response.data.message)

            vm.loading = false
        }
    },
}
</script>

<style scoped>

.member-container {
    background-color: var(--v-secondary-base);
    border-radius: 12px;
    padding: 4px 8px;
    color: #FFFFFF;
}

</style>